import React from "react";
import styled from "react-emotion";
import Layout from "../components/Layout";
import {Box} from "../styles/basics";

const ContactName = styled("div")({
  fontWeight: "bold",
  marginBottom: "2rem",
});

const Details = styled("div")({
  fontSize: "0.9rem",
});

const Contact = () => (
  <Layout title={"Choice – Kontakt & Impressum"}>
    <Box css={{textAlign: "center"}}>
      <h1>Kontakt</h1>
      <ContactName>
        Prof. Dr. Thomas Koinzer
        <br />
        Humboldt-Universität zu Berlin
        <br />
        Institut für Erziehungswissenschaften
      </ContactName>
      <Details>
        <h3>Adresse</h3>
        <p>
          Unter den Linden 6<br />
          10099 Berlin
        </p>
        <h3>Sitz</h3>
        <p>
          Geschwister-Scholl-Straße 7<br />
          10117 Berlin – Raum 328
        </p>
        <h3>Telefon</h3>
        <p>+49 (0) 30 2093 66852</p>
        <h3>Telefax</h3>
        <p>+49 (0) 30 2093 1828</p>
        <h3>Email</h3>
        <p>thomas.koinzer@hu-berlin.de</p>
      </Details>
    </Box>
    <Box>
      <h2>Haftungshinweis</h2>
      <p>
        Die Inhalte dieser Webseiten werden von uns ständig geprüft und aktualisiert. Trotz aller
        Sorgfalt können sich Angaben zwischenzeitlich aber verändert haben. Deshalb kann keine
        Haftung oder Garantie für die Richtigkeit, Vollständigkeit und Aktualität dieser Inhalte
        übernommen werden. Wir behalten uns vor, die Informationen in dieser Site jederzeit und ohne
        vorherige Ankündigung zu ändern, zu aktualisieren oder zu löschen.
      </p>
      <p>
        Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte
        externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
        verantwortlich.
      </p>

      <h3>Quellennachweis</h3>
      <p>
        Icons designed by{" "}
        <a href="http://www.freepik.com/" rel="nofollow">
          Freepik
        </a>
      </p>
    </Box>
    <Box>
      <h2>Datenschutzerklärung</h2>
      <p>
        Hier finden Sie die{" "}
        <a href="https://www.hu-berlin.de/de/hu/impressum/datenschutzerklaerung" rel="nofollow">
          Datenschutzerklärung
        </a>{" "}
        zu den Websiten der Humboldt-Universität zu Berlin.{" "}
      </p>
    </Box>
  </Layout>
);

export default Contact;
